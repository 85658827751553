/* Define the CSS animation keyframes for sliding left */
@keyframes slide-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Define the CSS animation keyframes for sliding right */
  @keyframes slide-right {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* Style for the container of the quote carousel */
  .quote-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  /* Style for the quote card */
  .quote-card {
    width: 100%;
    transition: transform 0.3s ease-in-out;
    animation-duration: 0.3s;
  }
  
  /* Apply the sliding animation to the card based on the slideDirection state */
  .quote-card[style*="left"] {
    animation-name: slide-left;
  }
  
  .quote-card[style*="right"] {
    animation-name: slide-right;
  }
  