.banner-container {
  max-height: 50px;
  width:100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-top: 15px;
  background-color: #ffff;
}

.image-wrapper {
  display: flex;
  animation: ticker 18s linear infinite;
}

.image-slide {
  padding: 0 40px; 
  transition: transform 0.8s linear;
}

.image-slide.active {
  transform: translateX(-100%);
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
